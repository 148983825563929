.customers{
    width: 100%;
}
.customers .container{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 80px;
}
.customers_title{

}
.customers_title::after{
    width: 41%;
}
.customers_icon_box{
    display: flex;
    gap: 75px;
    position: absolute;
    transition: 500ms;
    opacity: 0;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
}
.customers_icon_box_mini{
    width: 218px;
    height: 218px;
    box-sizing:border-box;
    background: #FFFFFF;
    border-radius: 18.6217px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 21px;
    -o-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    filter: drop-shadow(0px 3.82498px 3.82498px rgba(0, 0, 0, 0.25));
    
}

.transition{
    -o-transform: scale(2, 0.5);
  -ms-transform: scale(1.2, 0.5);
  -moz-transform: scale(1.2, 0.5);
  -webkit-transform: scale(1.2, 0.5);
  transform: scale(0, 0);
}
.active_box{
    opacity: 1;

}
.customers_icon_img{
    max-width: 100%;
    max-height: 100%;
}
.customers_body{
    position: relative;
    height: 400px;
    width: 100%;
    display: flex;
    justify-content: center;
}
.strategy_switch:hover{
    background: linear-gradient(5.65deg, rgba(255, 255, 255, 0) -8.26%, rgba(255, 255, 255, 0) 63.99%), linear-gradient(180deg, #9557f7c7 0%, #8449e2 100%), radial-gradient(100% 105.86% at 50% 0%, rgba(255, 255, 255, 0.637) 0%, rgba(255, 255, 255, 0) 55.73%);
    transition: 300ms;
}
section.customers {
    margin-bottom: 100px;

}
.strategy_switch_box {
    border-radius: 10px;
    display: flex;
    padding: 0px 0px 50px 0;
    width: 100%;
    justify-content: center;
}
.strategy_switch {
    font-style: normal;
    font-weight: 400;
    font-size: clamp(16px, 1.5em, 20px);
    line-height: 110%;
    text-align: center;
    color: #fff;
    background: linear-gradient(5.65deg, rgba(255, 255, 255, 0) -8.26%, rgba(255, 255, 255, 0) 63.99%), linear-gradient(180deg, rgb(150, 88, 248) 0%, rgb(104, 57, 179) 100%), radial-gradient(100% 105.86% at 50% 0%, rgba(255, 255, 255, 0.637) 0%, rgba(255, 255, 255, 0) 55.73%);
    border-radius: 0px;
    padding: 21px;
    width: 278px;
    display: flex;
    height: 90px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 300ms;
}
.active_switch {
    background: linear-gradient(5.65deg, rgba(255, 255, 255, 0) -8.26%, rgba(255, 255, 255, 0) 63.99%), linear-gradient(180deg, rgb(132, 73, 226) 0%, rgb(71, 39, 122) 100%), radial-gradient(100% 105.86% at 50% 0%, rgba(255, 255, 255, 0.637) 0%, rgba(255, 255, 255, 0) 55.73%);
    border-bottom: 6.845333px solid  rgb(85 47 147);
}
.strategy_switch:last-child {
    border-radius: 0px 10px 10px 0px;
}
.strategy_switch:first-child {
    border-radius: 10px 0px 0px 10px;
}