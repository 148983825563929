* {
    margin: 0;
    padding: 0;

    box-sizing: border-box;
    font-family: "Inter";
}
.container {
    width: 80%;
    margin: 0 auto;
}
html {
    scroll-behavior: smooth;
}
.hellov {
    background: #000e32;
    background: url(../../public/img/fon.jpg);
    background-size: cover;
    background-repeat: no-repeat;

    height: 100vh;
}
a {
    text-decoration: none;
}
.textColor {
    color:#000;
    background-image: linear-gradient(114.66deg, #db00ff 34.34%, #000aff 138.13%);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
}
.h2 {
    font-size: clamp(36px, 3em, 70px);
    font-weight: 600;
    line-height: clamp(50px, 125%, 84px);
    letter-spacing: 0em;
    text-align: left;
}
.sinColor {
    color: #5306ff;
}
button {
    cursor: pointer;
    box-shadow: none;
    border: none;
    text-align: center;
}
svg {
	display: none;
}
.main_menu {
    position: fixed;
    left: 0;
    top: 50%;
    background: #7046cddb;
    border-radius: 25px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: min-content;
    // height: 230px;
    z-index: 999999;
    // padding: 15px 20px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10%;
    z-index: 9999;
    & .e1 {
        opacity: 0;
        top: 90%;
        display: none;
    }
    &_filter{

    }
    &_but {
        color: #fff;
        text-align: center;
        transition: 300ms;
        padding: 10px 20px;
        position: fixed;
        top: 92%;
        right: 10%;
        width: 180px;
        height: 66px;
        background: rgb(86, 47, 148);
        filter: url("#fancy-goo");
        .tab-head {
            width: 180px;
            position: absolute;
            height: 50px;
            top: 12px;
            left: 0px;
            z-index: 1;
        
        }
    
        .tab-head-bottom {
            position: absolute;
            width: 243px;
            height: 46px;
            left: -35px;
            top: 38px;

            background: #fff;
        }
        transition: top 1.5s
            cubic-bezier(0.02, 0.01, 0.31, 1.04);
        &:hover {
            text-decoration: underline;
        }

        &.e2 {
            right: 30%;

            color: #000;
            top: 91%;
            z-index: 15;

            &.active {
                top: 0%;
            }
            background: #fff;
       
        }

        &.e3 {
            right: 21%;
            top: 92%;
            z-index: 22;

            &.active {
                top: 1%;
            }
            .tab-head-bottom {
              
                background: linear-gradient(0.79deg, #522d8e 33.92%, #583097 106.99%);
            }
        }
        &.e4 {
            color: #000;
            right: 12%;
            top: 93%;
            z-index: 24;
            &.active {
                top: 2%;
            }
            background: #fff;
          background: #fff;
        }
        &.e5 {
            right: 3%;
            top: 94%;
            z-index: 26;

            &.active {
                top: 3%;
            }
            .tab-head-bottom {
              
                background: rgb(86, 47, 148);
            }
        }
    }
}
.header {
    padding-top: 36px;
    .container {
        background: #ffffff33;
        border-radius: 15px;
    }
    &_strok{
        color: #fff;
        font-size: clamp(14px, 1.5em, 36px);
        text-transform: uppercase;
    }
    &_contakt {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
    }
    &_chat {
        cursor: pointer;
        height: 42px;
        width: 42px;
        position: relative;
        &:hover {
            .header_chat_list {
                max-height: 1000%;
                opacity: 1;
            }
        }
        &_list {
            position: absolute;
            top: 110%;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 6px;
            left: 0;
            width: 100%;
            overflow: hidden;
            max-height: 0%;
            transition: 300ms;
            opacity: 0;
            cursor: pointer;
            z-index: 5;
            &:hover {
                max-height: 1000%;
                opacity: 1;
            }
            a {
                &:hover {
                    img {
                        transition: 600ms;
                        filter: invert(1);
                    }
                }
            }
        }
    }
    &_logo {
        height: 70px;
        width: auto;

        transition: 200ms;
        transform: scale(0.95);

        &:hover {
            filter: drop-shadow(-6px 7px 0px black);
            transform: scale(1);
        }
    }

    &_content {
        padding: 5px 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &_button {
        font-size: 16px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        color: #fff;
        text-wrap: nowrap;
        background: none;
        border: 0.5px solid #ffffff99;
        border-radius: 30px;
        padding: 13px 19.5px;
    }

    &_tel {
        font-size: 22px;
        font-weight: 500;
        line-height: 27px;
        letter-spacing: 0em;
        color: #fff;
        display: flex;
        align-items: center;
        border-radius: 25px;
        transition: 1s;
        background: transparent;

        max-width: 42px;
        &:hover {
            max-width: 2000px;
            transition: 1s;

            background: #1609147a;
        }
        img {
            width: 42px;
            height: 42px;
        }
        span {
            margin: 0px 10px;

            overflow: hidden;
            text-wrap: nowrap;
        }
    }
}
.videlBlu {
    color: #000;
    background: #6be3fe;
    border-radius: 35px;
    padding: 0px 20px;
    text-wrap: nowrap;
}
:root {
    --delay: 0;
    --duration: 1000ms;
    --iterations: 1;
}
.fease {
    &_content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 9px;

        &_textSlog {
            overflow: hidden;
            position: relative;


            
        }
        #flip {
            height: 150px;
            overflow: hidden;
            width: 100%;
            &.textmin{
                height: 100px;
                & .fease_content_t3{
                    height: 100px;
                }
            }
        }
        
    

        &_t1 {
            font-size: 62px;
            font-weight: 700;
            line-height: 75px;
            letter-spacing: 0em;
            text-align: left;
            color: #ffffff;
            opacity: 0;
            transition: 300ms;
            animation-name: go2;
            animation-timing-function: ease;
            animation-duration: 0.7s;
            position: absolute;
            bottom: -150px;
            height: 150px;
            opacity: 0;
            transition: all 500ms;
            transform: rotateX(84deg) perspective(60px);

            &.active {
                bottom: 0;
                opacity: 1;
                transform: rotateX(0);
            }
        }
        &_t2 {
            font-size: 48px;
            font-weight: 500;
            line-height: 58px;
            letter-spacing: 0em;
            text-align: left;
            color: #ffffff;
            margin-bottom: 30px;
        }
        &_t3 {
            font-size: 24px;
            font-weight: 500;
            line-height: 120%;
            letter-spacing: 0em;
            text-align: left;
            color: #ffffff;
            max-width: 60%;
        }

        &_button {
            background: linear-gradient(
                114.66deg,
                #db00ff 34.34%,
                #000aff 138.13%
            );
            padding: 15px 41px;
            position: relative;
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0em;
            color: #ffffff;
            text-transform: uppercase;
            border-radius: 25px;
            margin-top: 30px;
            overflow: hidden;
            text-wrap: nowrap;

            span {
                padding-left: 20px;
            }

            box-shadow: -1px 4px 9px #f243f559;
            transition: 300ms;
            // transition-timing-function: ease-out;
            &:active {
                box-shadow: inset 1px 2px 16px #00000038;
            }
            &:hover {
                box-shadow: -1px 1px 21px rgb(112 39 231);
                
            }
            &::after {
                content: "";
                -webkit-animation: movingFlare 2s
                    ease-in-out infinite;
                animation: movingFlare 2s ease-in-out
                    infinite;
                background: #ffffff8e;
                background: linear-gradient(
                    180deg,
                    hsla(0, 0%, 100%, 0.5) 0,
                    #ffffff8c 50%,
                    hsla(0, 0%, 100%, 0.5)
                );
                display: block;
                height: 200px;
                left: -40px;
                margin-left: 50px;
                position: absolute;
                top: -100px;
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
                width: 20px;
                z-index: 1;
            }
            @keyframes movingFlare {
                0% {
                    left: -30px;
                    margin-left: 0;
                }
                30%,
                100% {
                    left: 110%;
                    margin-left: 80px;
                }
            }
        }
    }
    position: relative;
    height: 80vh;
    display: grid;
    grid-template-columns: 60% 40%;

    &_karusel {
        .-el1 {
            top: -63px;
            left: 115px;
        }
        .-el2 {
            top: 121px;
            left: 371px;
        }
        .-el3 {
            top: 321px;
            left: 75px;
        }
        .-el4 {
            top: 121px;
            left: -121px;
        }
        position: relative;
        right: 0;
        height: 100%;
        width: 100%;
        top: 5%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;

        &-krug {
            border: 2.35px solid #6be3fe80;
            width: 495.59px;
            height: 431.5px;
            border-radius: 100%;
            position: relative;
        }

        &_element {
            // cursor: pointer;
            width: 234.51px;
            height: 152.68px;
            background: #fff;
            box-shadow: 0px 2.97px 2.97px 0px
                rgba(0, 0, 0, 0.1490196078);
            border-radius: 15px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            overflow: hidden;
            position: absolute;
            transition: 1.5s;
            &.active {
                z-index: 2;
                transform: scale(1.5) translateX(-60px);
                // animation: 2s act1 ;
                .fease_karusel_element_fase-content {
                    opacity: 0;
                    max-height: 0px;
                    overflow: hidden;
                    padding: 0;
                }
                .fease_karusel_element_fase-footer {
                    height: 100%;

                    &_menu {
                        display: none;
                    }
                }
                .fease_karusel_element_fase-footer-activeContent {
                    display: block;
                    opacity: 1;
                }
            }
            @keyframes act1 {
                0% {
                    transform: scale(1.5);
                }
                50% {
                    transform: scale(1.5) translateX(-60px)
                        perspective(200px);
                }
                100% {
                }
            }
            &_fase {
                &-content {
                    padding: 16px 23px;
                    position: relative;
                    height: 100%;
                    transition: max-height 300ms;
                    &_name {
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 21px;
                        letter-spacing: 0em;
                        text-align: left;
                        color: #071121;
                    }

                    &_img {
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        width: auto;
                        height: auto;
                    }
                }

                &-footer {
                    transition: 800ms;
                    background: #6400ed;
                    height: 20%;
                    display: flex;
                    align-items: center;
                    padding: 10px 11px;

                    &-activeContent {
                        height: 100%;
                        display: none;
                        opacity: 0;
                        transition: opacity 500ms;
                        &_head {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            padding-bottom: 20px;
                            button {
                                font-size: 13px;
                                font-weight: 400;
                                line-height: 14px;
                                letter-spacing: 0em;
                                text-align: left;
                                background: #fff;
                                padding: 4px 18px;
                                border-radius: 25px;
                            }

                            img {
                                height: 40px;
                                width: 40px;
                            }
                        }
                        p {
                            font-size: 15px;
                            font-weight: 400;
                            line-height: 20px;
                            letter-spacing: 0em;
                            text-align: left;
                            color: #ffffff;
                        }
                    }
                    &_menu {
                        width: 16.48px;
                        height: 9.62px;
                    }
                }
            }
        }
    }
}
.imgHov {
    position: absolute;
    z-index: 9;
    // background: #000;
    height: 200px;
    width: 200px;

    display: flex;
    justify-content: center;
    align-items: center;
    perspective: 40px;

    overflow: hidden;
    img {
        transition: transform 0.5s;
        max-height: 100%;
        width: auto;
    }
   
}

.modern {
    position: fixed;
    border-top-left-radius: 35px;
    border-top-right-radius: 35px;
    background: #fff;
    top: 95%;
    width: 100%;
    height: 100vh;
    z-index: 10;
    padding: 80px 0px;
    transition: top 1.5s
        cubic-bezier(0.02, 0.01, 0.31, 1.04);
    left: 0;

    &.active {
        top: 4%;
    }
    h2 {
        margin-bottom: 30px;
    }

    &_slider {
        width: 100%;
        height: 100%;
        display: flex;
        gap: 30px;

        &_content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            align-items: flex-start;
            gap: 40px;
            &_iconBox {
                img {
                    width: 90px;
                    height: 90px;
                }
            }
            &_text {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;
                align-items: flex-start;
                gap: 20px;
                &_name {
                    font-size: 36px;
                    font-weight: 600;
                    line-height: 44px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #5306ff;
                }

                &_opis {
                    font-size: 16px;
                    font-weight: 250;
                    line-height: 24px;
                    letter-spacing: 0em;
                    text-align: left;
                    width: 60%;
                }

                &_date {
                    padding: 10px 15px;
                    background: #ebf3ff;
                    border-radius: 10px;
                    width: max-content;
                    p {
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 16px;
                        letter-spacing: 0em;
                        text-align: left;
                        color: #000aff;
                    }
                    span {
                        font-size: 32px;
                        font-weight: 400;
                        line-height: 32px;
                        letter-spacing: 0em;
                        text-align: left;
                        color: #000aff;
                    }
                }
            }
        }
    }
    // слайдер
    .slayden-savnena {
        height: 25vw;

        max-height: 748px;
        max-width: 1000px;
        overflow: hidden;
        width: 70vw;
    }
    .slayden-savnena figure {
        background-image: url(../../public/img/slider/po1.jpg);
        background-size: cover;
        font-size: 0;
        height: 100%;
        margin: 0;
        position: relative;
        width: 100%;
    }
    #kobavenusab {
        background-image: url(../../public/img/slider/do1.jpg);
        background-size: cover;
        bottom: 0;
        border-right: 5px solid rgba(243, 240, 240, 0.7);
        box-shadow: 10px 0 15px -13px #171717;
        height: 100%;
        max-width: 85.6%;
        min-width: 0.6%;
        overflow: visible;
        position: absolute;
        width: 50%;
        animation: first 2s 1 normal ease-in-out 0.1s;
        -webkit-animation: first 2s 1 normal ease-in-out
            0.1s;
    }
    input#pedsumid {
        -moz-appearance: none;
        -webkit-appearance: none;
        border: none;
        background: transparent;
        cursor: col-resize;
        height: 100vw;
        left: 0;
        margin: 0;
        outline: none;
        padding: 0;
        position: relative;
        top: -100vw;
        width: 100%;
    }
    input#pedsumid::-moz-range-track {
        background: transparent;
    }
    input#pedsumid::-ms-track {
        border: none;
        background-color: transparent;
        height: 100vw;
        left: 0;
        outline: none;
        position: relative;
        top: -100vw;
        width: 100%;
        margin: 0;
        padding: 0;
        cursor: col-resize;
        color: transparent;
    }
    input#pedsumid::-ms-fill-lower {
        background-color: transparent;
    }
    input#pedsumid::-webkit-slider-thumb {
        -webkit-appearance: none;
        height: 100vw;
        width: 0.5%;
        opacity: 0;
    }
    input#pedsumid::-moz-range-thumb {
        -moz-appearance: none;
        height: 100vw;
        width: 0.5%;
        opacity: 0;
    }
    input#pedsumid::-ms-thumb {
        height: 100vw;
        width: 0.5%;
        opacity: 0;
    }
    input#pedsumid::-ms-tooltip {
        display: none;
    }
    #kobavenusab::before {
        background: url(https://zornet.ru/ABVUN/Aba/detunis/comparision.png)
            no-repeat scroll 0 center transparent;
        background-size: contain;
        content: " ";
        float: right;
        height: 100%;
        margin-right: -34px;
        position: relative;
        top: 0;
        width: 64px;
    }
    @keyframes first {
        0% {
            width: 0%;
        }
        50% {
            width: 80%;
        }
        100% {
            width: 50%;
        }
    }
    @-webkit-keyframes first {
        0% {
            width: 0%;
        }
        50% {
            width: 80%;
        }
        100% {
            width: 50%;
        }
    }
}

.prich {
    position: fixed;
    border-top-left-radius: 35px;
    border-top-right-radius: 35px;
    top: 96%;
    background: linear-gradient(
        0.79deg,
        #000000 33.92%,
        #6236a9 106.99%
    );
    width: 100%;
    height: 100vh;
    z-index: 20;
    padding: 120px 0px;
    transition: top 1.5s
        cubic-bezier(0.02, 0.01, 0.31, 1.04);
    left: 0;
    &.active {
        top: 5%;
    }
    .h2 {
        color: #fff;
        margin-top: 30px;
    }

    &_content {
        &-list {
            height: 100%;
            min-height: 500px;
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-around;
        }
        &-item {
            width: 230px;
            height: 130px;
            position: relative;
            
                z-index: var(--i);
            
            &::after {
                content: "";
                position: absolute;
                width: 300px;
                height: 300px;
                border-radius: 50%;
                border: 2px solid #ffffff;
                top: -48%;
                left: -29%;
                cursor: pointer;
                transition: 300ms;
                background: #000;
                z-index: -1;
            }
            &:hover {
                .prich_content-item_text {
                    color: #000;
                    z-index: 2;
                }
                
                & > .prich_content-item_head img {
                    z-index: 2;
                    transform: scale(1.5);

                }
                z-index: 10;
               
                &::after {
                    content: "";
                    z-index: 1;

                    transform: scale(1.3);
                    background: #fff;
                }
            }
            &_head {
                width: 100%;
                height: 33px;
                img {
                    height: 100%;
                    width: auto;
                    position: relative;
                    transition: 300ms;
                }
            }
            &_text {
                max-width: 200px;
                font-size: 16px;
                font-weight: 500;
                line-height: 22px;
                letter-spacing: 0em;
                text-align: left;
                color: #ffffff;
                margin-top: 20px;
                position: relative;

            }
        }
    }
}

.webstud {
    position: fixed;
    border-top-left-radius: 35px;
    border-top-right-radius: 35px;
    top: 97%;
    background: #fff;
    width: 100%;
    height: 100vh;
    z-index: 23;
    padding: 120px 0px;
    transition: top 1.5s
        cubic-bezier(0.02, 0.01, 0.31, 1.04);
    left: 0;
    &.active {
        top: 6%;
    }
    .h2 {
        // color: #fff;
        margin-top: 30px;
    }

    &_content {
        display: grid;
        grid-template-columns: 2fr 2fr;

        &_text {
            &-te {
                font-size: 24px;
                font-weight: 400;
                line-height: 31px;
                letter-spacing: 0em;
                text-align: left;
                margin-bottom: 20px;
            }

            &-nagrad {
                display: grid;
                grid-template-columns: repeat(2, 2fr);
                gap: 20px;
                &_item {
                    display: flex;
                    align-items: center;
                    border-radius: 25px;

                    transition: 300ms;
                    border: 2px solid #fff;

                    &:hover {
                        box-shadow: 3.732000351px 1.2440000772px 9.952000618px 0px rgb(136 165 191 / 10%);
                        border: 2px solid #ad03ff42;

                        .webstud_content_text-nagrad_item_imgBox{
                            transform: scale(1.2);
                        }
                    }
                    
                    &_imgBox {
                        width: 100px;
                        height: 100px;
                        min-width: 100px;
                        padding: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin: 20px;
                        transition: 300ms;
                        img {
                            height: 100%;
                            width: auto;
                        }
                    }

                    &_text {
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 16px;
                        letter-spacing: 0em;
                        text-align: left;
                        pointer-events: none;
                    }
                }
            }
        }

        &-imgGl {
            img {
                width: 100%;
                height: auto;
            }
        }
    }
}

.fos {
    & > .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 25px;
        position: relative;
    }
    &_user{
        display: flex;
        gap: 20px;
        a{
            background: linear-gradient(0.79deg, #3a2065 33.92%, #552f93 106.99%);
            padding: 10px 20px;
            border-radius: 15px;
        }
        .contactBlock{
            display: flex;
            align-items: center;
            gap: 15px;
        }
        p{
            color: #fff;
        }
        img{
            border-radius: 50%;
            width: 50px;
            height: 50px;
        }
    }
    position: fixed;
    border-top-left-radius: 35px;
    border-top-right-radius: 35px;
    top: 98%;
    background: linear-gradient(
        0.79deg,
        #000000 33.92%,
        #6236a9 106.99%
    );
    width: 100%;
    height: 100vh;
    z-index: 30;
    padding: 60px 0px;
    transition: top 1.5s
        cubic-bezier(0.02, 0.01, 0.31, 1.04);
    left: 0;
    &.active {
        top: 7%;
    }
    .h2 {
        color: #fff;
        margin-top: 30px;
        text-align: center;
        max-width: 790px;
    }
    &-ps {
        font-size: 24px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: center;
        color: #fff;
    }

    &-form {
        background: #ffffff33;
        max-width: 730px;
        width: 100%;
        border-radius: 20px;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 8px;
        padding: 30px 64px;
        &_inputBox {
            display: block;
            width: 100%;
            input {
                all: unset;
                border: 1px solid #1e42ff33;
                background: #fff;
                padding: 21px 32px;
                border-radius: 25px;
                width: 89%;
            }
        }
    }
}
main {
    overflow: hidden;
}
.footer {
    background: rgba(255, 255, 255, 0.2);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &_aderes {
        font-size: 20px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: left;
        color: #fff;
    }

    &_tel {
        font-size: clamp(16px, 1.5em, 32px);
        font-weight: 400;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: left;

        color: #fff;
    }
    border-radius: 15px;
    padding: 10px 48px;
}
.popupFos{
    background: rgb(40 24 61 / 88%);
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0;
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transform: perspective(2000);
    transition: 300ms;
    &.active{
        height: 100%;

    }
    &_title{
        font-size: 24px;
        font-weight: 500;
        line-height: 34px;
        letter-spacing: 0em;
        text-align: center;

    }
    &_content{
        min-width: 30%;

        display: flex;
        flex-direction: column;
        gap: 20px;
        background: #fff;
        padding: 30px 20px;
        border-radius: 25px;
    }
    .fos-form_inputBox input {
        width: 86%;
    }
}
@media only screen and (max-width: 1600px) {
    .fease_karusel-krug {
        transform: scale(0.7);
    }
    .customers_icon_box {
        gap: 30px !important;
    
    }
    .customers_icon_box_mini{
        width: 190px !important;
        height: 190px !important;

    }

    section{
        padding: 28px 0px !important;
    }
    .fos .h2 {
        margin-top: 0px;
    }
}
@media only screen and (max-width: 1400px) {
    .fease_karusel-krug {
        transform: scale(0.7);
    }
    .fease_content_t1 {
        font-size: clamp(30px, 4em, 80px);
        line-height: 120%;
    }
    .fease_content_t2 {
        font-size: clamp(10px, 1.5em, 48px);

        line-height: 120%;
    }
    .fease_content_t3 {
        font-size: clamp(10px, 1em, 24px);
        line-height: 120%;
    }
    .modern_slider_content {
        gap: 30px;
    }
    .webstud_content_text-te {
        &:first-child {
            margin-top: 20px;
        }
        font-size: clamp(16px, 2.5em, 24px);
    }
    .videlBlu {
        text-wrap: nowrap;
    }
    .fease_content_t2{
        br{
            display: none;
        }
    }
    .fease_karusel-krug {
        min-width: 495.59px;
        right: -47px;
    }
    .fease {
        grid-template-columns: 87% 22%;
    }
    .fos > .container {
        gap: 5px;
    }
    .imgHov{
        display: none;
    }
    .fease_karusel {
        align-items: flex-end;
    }
    .fos-form {
        padding: 38px 100px;
    }
    .prich_content-list {

        display: grid;
        grid-template-columns: repeat(3,1fr);
    }
    .prich_content-item::after {
        content: "";
        width: 270px;
        height: 270px;
        top: -40%;
        left: -26%;
    }
    .main_menu_but.e4 {
        right: 21%;
    }
    .main_menu_but.e3 {
        right: 40%;
    }
    .main_menu_but.e2 {
        right: 58%;
    }
    section{
        padding: 50px 0px !important;
    }
    .strategy_switch_box {
       
        flex-direction: column;
    }
    .strategy_switch:first-child {
        border-radius: 10px 10px 0px 0px !important;
    }
    .strategy_switch{
        width: 100% !important;
        height: 37px !important;
        font-size: clamp(12px, 1em, 20px) !important;
        br{
            display: none;
        }
    }
    .strategy_switch:last-child {
        border-radius: 0px 0px 10px 10px !important;
    }
    .customers_icon_box {
        gap: 10px !important;
    }
    
}
@media only screen and (max-width: 600px) {
    .fease {
        display: flex !important;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .fease_karusel_element.active {
        z-index: 2;
        transform: scale(1.8) translateX(-60px);
    }
    .customers_icon_box_mini {
        width: 120px !important;
        height: 120px !important;
    }
    .prich {
        .fease_content_button {
            margin-top: 120px;
        }   
    }
    .customers_body {
        height: 700px !important;
    }
    .webstud_content-imgGl{
        margin-top: 30px;
    }
    .main_menu_but {
        display: none;
    }
    .fease_karusel {
        height: 45%;
    }
    .fease_content #flip {
        height: 106px;
        margin-top: 0px;
    }
    .fease_content_t1.active {
        bottom: -40px;
    }
    .fease_karusel-krug {
        right: -297px;
        transform: scale(0.8);
    }
    section {
        position: static !important;
        height: auto !important;
    }
    .fease {
        position: relative;
        height: auto;
    }
    
    .container {
        width: 90%;
    }
    .fease_content_t1 {
        font-size: clamp(23px, 2em, 80px);
        margin-top: 25px;
    }
    .modern_slider {
        flex-direction: column;
    }
    .fease_content_t3 {
        max-width: none;
        font-size:  1em;

    }
    .modern .slayden-savnena {
        height: 57vw;
        width: 91vw;
    }
    .h2 {
        font-size: clamp(30px, 2em, 70px);
    }
    .prich_content-list {
        margin-top: 43px;
        gap: 9px;
        display: grid;
        row-gap: 51px;
        grid-template-columns: 2fr 2fr;
        justify-items: end;
    }
    .webstud_content {
        display: block;
    }
    .webstud_content_text-nagrad {
        display: block;
    }
    .fos-form {
        padding: 38px 27px;
    }
    .footer {
        gap: 20px;
        flex-direction: column;
    }
    .fos-form_inputBox input {
        width: 82%;
    }
    .h2 {
       text-align: center;
    }
    .fos_user {
        flex-direction: column;
    }
    .fease_content_t2 {
        padding: 20px 0px;
        margin: 0;
    }
    .fease_content #flip.textmin {
        height: 62px;
    }
    .prich_content-item {
        margin-right: 20px;
        margin-top: 20px;
    }
    .prich_content-item:hover::after {
        transform: scale(1.1);
    }
    .fos > .container {
        gap: 25px;
    }
    .header_logo {
        height: auto;
        width: 100%;
    }
    .header {
        padding-top: 0;
    }
    .fease_content_button {
        font-size: 3.5vw;
        width: 78%;
    }
    .prich_content-list {
        grid-template-columns:  2fr;
    }
    .prich{
        h2{
            br{
                display: none;
            }
        }
    }
    .footer_aderes{
        text-align: center;
    }
    .header_strok {
        font-size: 14px;
    }
}
@media only screen and (max-width: 400px) {
    
    // .fease_karusel-krug {
    //     right: -23px;
    //     transform: scale(0.4);
    // }
   
}

